import React from "react";
import LoaderImg from "../../assets/images/loader.gif";

export default function Loader() {
  return (
    <React.Fragment>
      <div className="portfolio-section loader-popup mb-loader">
        <div className="row">
          <div className="container-loader mb-loader">
            <div className="loaderapp">
              <img src={LoaderImg} width={80} alt="...Loading" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
