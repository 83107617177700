const Error = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ fontSize: "2em", marginBottom: "20px", marginTop: "150px" }}>
        404 - URL NOT FOUND
      </h1>
      <p style={{ fontSize: "1.2em" }}>
        The URL you are looking for does not exist.
      </p>
    </div>
  );
};

export default Error;
