import axios from "axios";

// Get the base URLs and API version from environment variables
const BASE_URL = process.env.REACT_APP_CLIENT_URL;
const BASE_URL_BACKOFFICE = process.env.REACT_APP_BACKOFFICE_URL;
const API_VERSION = process.env.REACT_APP_VERSION;

// Create axios instances with versioned base URLs
const client = axios.create({ baseURL: `${BASE_URL}/${API_VERSION}` });
const clientBackoffice = axios.create({ baseURL: `${BASE_URL_BACKOFFICE}/${API_VERSION}` });

export const request = ({ ...options }) => {
  const onSuccess = (response) => response;
  const onError = (error) => error;
  return client(options).then(onSuccess).catch(onError);
};

export default axios.create({ baseURL: `${BASE_URL}/${API_VERSION}` });

export const requestBackoffice = ({ ...options }) => {
  const onSuccess = (response) => response;
  const onError = (error) => error;
  return clientBackoffice(options).then(onSuccess).catch(onError);
};
