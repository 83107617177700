import React from "react";
import LOGO from "../../assets/images/logo.svg";
import Facebook from "../../assets/images/facebook.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import LinkedIn from "../../assets/images/linkedin.png";
export default function Footer(data) {
  const clubName = data?.data?.data?.name;
  const footer = data?.data?.data?.footer;
  const logo = data?.data?.data?.logo;
  const navBarData = data?.data?.data?.navBarData;
  const districtLog = data?.data?.data?.districtLogo;

  const checkIfUrlExists = (url) => {
    if (!navBarData || !Array.isArray(navBarData)) return false;
    return navBarData.some((item) => Object.values(item).includes(url));
  };
  return (
    <>
      <footer id="footer" className="footer-one">
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="col-lg-4 p20 l-float">
                  <img src={logo ?? LOGO} className="width-20" alt="" />
                  <p className="text-white1">
                    {footer ??
                      "Registered under Charity Trusts Act Regn no. E-1315 (Thane)"}
                  </p>
                </div>
                <div className="col-lg-4 p20 l-float">
                  <h5 className="text-white1 mb-4">{clubName ?? ""}</h5>
                  <p className="footer-links">
                    <a className="text-white1" href="/">
                      Home
                    </a>
                    {navBarData
                      ? checkIfUrlExists("/whats-new") && (
                          <a className="text-white1" href="/whats-new">
                            WhatsNew
                          </a>
                        )
                      : null}
                  </p>
                  <p className="footer-links1">
                    {navBarData
                      ? checkIfUrlExists("/about-us") && (
                          <a className="text-white1" href="/about-us">
                            About Us
                          </a>
                        )
                      : null}
                    {checkIfUrlExists("/fellow-ship") && (
                      <a className="text-white1" href="/fellow-ship">
                        Fellowship
                      </a>
                    )}
                  </p>
                  <p className="footer-links2">
                    {navBarData
                      ? checkIfUrlExists("/contact-us") && (
                          <a className="text-white1" href="/contact-us">
                            Contact Us
                          </a>
                        )
                      : null}
                    {navBarData
                      ? checkIfUrlExists("/activities") && (
                          <a className="text-white1" href="/activities">
                            Activities
                          </a>
                        )
                      : null}
                  </p>
                </div>
                <div className="col-lg-4 p20 l-float">
                  <img src={districtLog ?? LOGO} className="width-16" alt="" />
                  <h5 className="text-white1 mt-4">Join Us</h5>
                  {data?.data?.data?.facebook ? (
                    <a href={data?.data?.data?.facebook} target="blank">
                      <img src={Facebook} className="pr12" alt="" />
                    </a>
                  ) : (
                    <a href="https://www.facebook.com/" target="blank">
                      <img src={Facebook} className="pr12" alt="" />
                    </a>
                  )}
                  {data?.data?.data?.twitter ? (
                    <a href={data?.data?.data?.twitter} target="blank">
                      <img src={Twitter} className="pr12" alt="" />
                    </a>
                  ) : (
                    <a href="https://www.twitter.com/" target="blank">
                      <img src={Twitter} className="pr12" alt="" />
                    </a>
                  )}
                  {data?.data?.data?.instagram ? (
                    <a href={data?.data?.data?.instagram} target="blank">
                      <img src={Instagram} className="pr12" alt="" />
                    </a>
                  ) : (
                    <a href="https://www.instagram.com/" target="blank">
                      <img src={Instagram} className="pr12" alt="" />
                    </a>
                  )}
                  {data?.data?.data?.linkedIn ? (
                    <a href={data?.data?.data?.linkedIn} target="blank">
                      <img
                        src={LinkedIn}
                        className="pr12"
                        height="30px"
                        alt=""
                      />
                    </a>
                  ) : (
                    <a href="https://www.linkedIn.com/" target="blank">
                      <img src={LinkedIn} className="pr12" alt="" />
                    </a>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <p className="text-white1 text-center">
                  © 2024 All rights reserved. {clubName ?? "Lions Club"} 
                </p>
                <p className="text-white1 text-center">
                  Powered by IT Mines Technology Private Limited
                </p>
              </div>
              <div className="col-lg-12">
                <p className="text-white1 text-right">
                  {data?.data?.data?.viewCount} views 
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
