import { useQuery } from "react-query";
import { request } from "../utility/axios-utils";

const serviceData = (url) => {
  
  return request({
    url: "/settings/getUIdata?url=" + url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const useContentData = (url) => {
  return useQuery("servicedetails", () => serviceData(url), {
    enabled: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};
