import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loader from "../FormsUI/Loader";
import HeaderLayout from "../components/HeaderLayout";
import { useContentData } from "../hooks/useFetchData";
import ErrorComponent from "./Error.jsx";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = React.lazy(() => import("./LandingPages/HomePage"));
const About = React.lazy(() => import("./LandingPages/AboutusPage.jsx"));
const Activities = React.lazy(() => import("./LandingPages/ActivitiesPage"));
const WhatsNew = React.lazy(() => import("./LandingPages/WhatsNewPage.jsx"));
const FellowShip = React.lazy(() => import("./LandingPages/FellowShipPage.jsx"));
const ContactUs = React.lazy(() => import("./LandingPages/ContactUsPage.jsx"));
const Notfound = React.lazy(() => import("./notFound"));
const SharedPage = React.lazy(() => import("../FormsUI/Popup/GetSharedBlog"));
const loadComponent = (component) => {
  return component;
};
const MainComponent = () => {
  const URL = window?.location?.host;
  const { data, isSuccess } = useContentData(URL);
  const fetchedServices = isSuccess ? data?.data?.body?.data : {};
  const description = fetchedServices?.body?.metadataDescriprition
  const keyword = fetchedServices?.body?.metadataKeyword
  const sectionData = fetchedServices?.body?.navBarData;
  const apiErrorStatus = data?.response?.data?.status ?? undefined;
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (location.pathname === "/shareblog" && params.has("blogId")) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  if (apiErrorStatus === 400) return <ErrorComponent />;

  if (!sectionData || sectionData.length === 0) {
    return null;
  }
  const componentMapping = {
    "/about-us": About,
    "/activities": Activities,
    "/whats-new": WhatsNew,
    "/fellow-ship": FellowShip,
    "/contact-us": ContactUs,
    "/shareblog": SharedPage,
  };
  const tenantId = fetchedServices?.body?.tenantId || "";
  const internetprofileData = fetchedServices?.body;
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{fetchedServices?.body?.name}</title>
          <meta
            name="description"
            content="Default page description"
            key="description"
          />
          <meta
            property="og:description"
            content="Default page description"
            key="og:description"
          />
          <meta name="keywords" content="default, keywords" key="keywords" />
          <link rel="canonical" href={window.origin} />
        </Helmet>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<HeaderLayout data={internetprofileData} />}>
              <Route
                path="/"
                element={
                  <Home
                    tenantId={tenantId}
                    clubName={fetchedServices?.body?.name}
                    description={description}
                    keyword={keyword}
                  />
                }
              />
              <Route
                path="/shareblog"
                element={
                  <SharedPage
                    tenantId={tenantId}
                    show={showModal}
                    handleClose={handleClose}
                  />
                }
              />
              {sectionData.map((navItem, index) => {
                const path = Object.values(navItem)[0];
                const Component = loadComponent(componentMapping[path]);
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <Component
                        tenantId={tenantId}
                        clubName={fetchedServices?.body?.name}
                      />
                    }
                  />
                );
              })}
            </Route>
            <Route path="*" element={<Notfound />} />
          </Routes>
        </Suspense>
      </HelmetProvider>
    </>
  );
};

export default MainComponent;
