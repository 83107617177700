import React, { useState } from "react";
import "../../assets/css/bootstrap.min.css";
import staticLogo from "../../assets/images/logo.svg";
import { Link, useLocation } from "react-router-dom";

function Header(props) {
  const dynamicLogo = props?.data?.data?.logo;
  const dynamicNavbarData = props?.data?.data?.navBarData;
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Close the menu when a menu item is clicked
  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      <div className="menu-bg">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="/">
              <img
                src={dynamicLogo ?? staticLogo}
                className="logo-width logo-height"
                alt="Logo"
              />
            </a>
          </nav>
        </div>
      </div>
      <div
        className={`menu-bg1 ${
          isSticky
            ? "bg-inverse fixed-top scrolling-navbar top-nav-collapse"
            : ""
        }`}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
              <ul className="navbar-nav mr-auto">
                <li
                  className={`nav-item ${currentPath === "/" ? "active" : ""}`}
                >
                  <Link className="nav-link" to="/" onClick={handleMenuItemClick}>
                    Home
                  </Link>
                </li>
                {dynamicNavbarData?.map((item) => {
                  const name = Object.keys(item)[0];
                  const path = item[name];
                  return (
                    <li
                      className={`nav-item ${
                        currentPath === path ? "active" : ""
                      }`}
                      key={name}
                    >
                      <Link
                        className="nav-link"
                        to={path}
                        onClick={handleMenuItemClick}
                      >
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
