import React from "react";
import Header from "../FormsUI/Header";
import Footer from "../FormsUI/Footer";
import { Outlet } from "react-router-dom";

const HeaderLayout = (data) => {
    
  if (data?.data?.favicon) {
    const link =
      document?.querySelector("link[rel*='icon']") ||
      document?.createElement("link");
    let extension = data?.faviconFileName?.split(".");
    const mimetype = extension?.pop()?.toLowerCase();
    link.type = "image/" + mimetype;
    link.rel = "shortcut icon";
    link.href = data?.data?.favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  return (
    <>
      <Header data={data} />
      <Outlet />
      <Footer data={data} />
    </>
  );
};

export default HeaderLayout;
